
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import { theme } from "./theme";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";


function Start() {
    const props = useSpring({
        from: { transform: 'translateY(-20%)', opacity: 0 },
        to: async next => {
            await next({ transform: 'translateY(0px)', opacity: 1 });
        },
        config: { duration: 1500 }
    });

    return (
        <div>
            <ThemeProvider theme={theme}>
                <>
                    <GlobalStyles />
                    <div className="startBg">
                        <animated.div className="start" style={props}>
                            <Link to="/main" className="start-logo"></Link>
                            <Link to="/main" className="start-enter"></Link>
                        </animated.div>
                    </div>
                </>
            </ThemeProvider>
        </div>
    );
}

export default Start;