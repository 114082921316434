import React, { Component } from "react";
import { MyGallery } from "../components";


class Gallery extends Component {

    importAll = (r) => { return r.keys().map(r); }

    render() {
        let context =  this.importAll(require.context('../../public/photo', false, /\.(png|jpe?g|svg)$/));

        return (
            <div>
                <div className="caption"><h2>Gallery</h2></div>
                <MyGallery context={context} />
            </div>
        );
    }
}

export default Gallery;