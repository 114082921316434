import styled from 'styled-components';

export const StyledMenu = styled.nav`
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: ${({ theme }) => theme.primaryLight};
      transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
      text-align: left;
      padding: 2rem;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      transition: transform 0.3s ease-in-out;
      z-index: 9998;
  
      @media (max-width: ${({ theme }) => theme.mobile}) {
            width: 100%;
      }
      
      .navLink {
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 0.5rem;
            color: ${({ theme }) => theme.primaryDark};
            text-decoration: none;
            transition: color 0.3s linear;
            @media (max-width: ${({ theme }) => theme.mobile}) {
                font-size: 1.5rem;
                text-align: center;
            }
            &:hover {
              color: ${({ theme }) => theme.primaryHover};
            }
      }
      .navLink.active {
            color: ${({ theme }) => theme.primaryHover};
      }
      
      .orange {
        color: #ff8903;
      }
`;
