import React, { Component } from "react";
import {Gallery, Item} from "react-photoswipe-gallery";

class Studio extends Component {

    goTo = link => { window.open(link, "_blank"); }

    render() {
        return (
            <div>
                <div className="caption"><h2>Studio</h2></div>

                <div className="studio-info">
                    <div className="studio-info-bar">
                        <ul>
                            <li><span className="studio-info-caption">U Černé Kotvy</span></li>
                            <li>Hájkova 1681/14</li>
                            <li>Praha 3-Žižkov</li>
                            <li>130 00</li>

                            <li><a href="mailto:tetovani@ucernekotvy.cz" title="E-mail to studio">tetovani@ucernekotvy.cz</a></li>
                            <li><a href="mailto:tondriktattoo@gmail.com" title="E-mail Tondrik">tondriktattoo@gmail.com</a></li>
                        </ul>

                    </div>
                    <div className="studio-info-bar">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.8406359101677!2d14.463075415756652!3d50.089270521204476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b93eb06738701%3A0x866bb6f9268eb68e!2zVSDEjGVybsOpIEtvdHZ5!5e0!3m2!1sen!2scz!4v1634564180873!5m2!1sen!2scz"
                            allowFullScreen="" className="studio-info-map" loading="lazy" title="Studio location"></iframe>
                    </div>
                    <div className="studio-info-bar studio-info-bar-smaller">
                        <div onClick={() => this.goTo("https://www.facebook.com/TondrikTattoo")}
                             className="studio-info-social-icon studio-info-social-fb" title="Facebook"></div>
                        <div onClick={() => this.goTo("https://www.instagram.com/tondrik_tattoo/")}
                             className="studio-info-social-icon studio-info-social-insta" title="Instagram"></div>
                        <div onClick={() => this.goTo("https://tondriktattoo.bigcartel.com/")}
                             className="studio-info-social-icon studio-info-social-big_cartel" title="Big Cartel"></div>
                    </div>
                </div>

                <div className="studio-info-gallery">
                    <Gallery>
                        <Item original="./studio/0.jpg" thumbnail="./studio/0.jpg" width="2000" height="1500">
                            {({ ref, open }) => (
                                <img ref={ref} onClick={open} src="./studio/0.jpg" className="studio-image" alt="Studio" />
                            )}
                        </Item>

                        <Item original="./studio/1.jpg" thumbnail="./studio/1.jpg" width="2000" height="1500">
                            {({ ref, open }) => (
                                <img ref={ref} onClick={open} src="./studio/1.jpg" className="studio-image" alt="Studio" />
                            )}
                        </Item>

                        <Item original="./studio/2.jpg" thumbnail="./studio/2.jpg" width="2000" height="1500">
                            {({ ref, open }) => (
                                <img ref={ref} onClick={open} src="./studio/2.jpg" className="studio-image" alt="Studio" />
                            )}
                        </Item>
                    </Gallery>
                </div>
            </div>
        );
    }
}

export default Studio;