import React, { Component } from "react";
import axios from "axios";

class Shop extends Component {

    constructor(props) {
        super(props);
        this.state = { products: [] }
    }

    async componentDidMount() {
        let products;

        try { products = await this.loadProducts(); }
        catch(e) { console.log(e); }

        this.setState({ products: products });
    }

    loadProducts = async () => {
        let products = [];

        let address = "https://api.bigcartel.com/tondriktattoo/products.json";
        let headers = { "Access-Control-Allow-Origin": "*" };

        await axios.get(address, { headers: headers }).then(
            (response) => { products = response.data; },
            (error) => { throw new Error(error); }
        );

        return products;
    }

    render() {
        const { products } = this.state;
        const goTo = link => { window.open(link, "_blank"); }

        return (
            <div>
                <div className="caption"><h2>Shop</h2></div>

                <div className="shop-list">
                {
                    products.map(function(p, idx) {
                        let soldout = (p.status === "sold-out") ? (<div className="shop-item-soldout">Sold Out</div>) : "";

                        return (
                            <div key={idx} className="shop-item" onClick={() => goTo("https://tondriktattoo.bigcartel.com/" + p.url) }>
                                <div className="shop-item-header">
                                    <div className="shop-item-header-name"> { p.name } </div>
                                    <div className="shop-item-header-price"> { p.price + ",-" } </div>
                                </div>
                                <div className="shop-item-image">
                                    <img className="shop-item-image-product" alt={ p.name } src={ p.images[0].url } />
                                    { soldout }
                                </div>
                                <div className="shop-item-description"> { p.description } </div>
                            </div>
                        );
                    })
                }
                </div>
            </div>
        );
    }
}

export default Shop;