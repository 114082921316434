import React, { useState, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { useOnClickOutside } from './hooks';
import { GlobalStyles } from './global';
import { theme } from './theme';
import {HashRouter, Route} from "react-router-dom";
import { Burger, Menu } from './components';
import FocusLock from 'react-focus-lock';

import Home from "./content/Home";
import Studio from "./content/Studio";
import GuestSpots from "./content/GuestSpots";
import Shop from "./content/Shop";
import Gallery from "./content/Gallery";
import Booking from "./content/Booking";

function Main() {
    const [open, setOpen] = useState(false);
    const node = useRef();
    const menuId = "main-menu";

    useOnClickOutside(node, () => setOpen(false));

    return (
        <ThemeProvider theme={theme}>
        <>
            <GlobalStyles />
            <div ref={node}>
                <FocusLock disabled={!open}>
                    <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
                    <Menu open={open} setOpen={setOpen} id={menuId} />
                </FocusLock>
            </div>

            <HashRouter>
                <div className="content">
                    <Route exact path="/" component={Home}/>
                    <Route path="/studio" component={Studio}/>
                    <Route path="/booking" component={Booking}/>
                    <Route path="/guestSpots" component={GuestSpots}/>
                    <Route path="/gallery" component={Gallery}/>
                    <Route path="/shop" component={Shop}/>
                </div>
            </HashRouter>
        </>
        </ThemeProvider>
    );
}

export default Main;
