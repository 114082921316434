import React, { Component } from "react";

class Booking extends Component {

    goTo = link => { window.open(link, "_blank"); }
    userLang = navigator.language || navigator.userLanguage;

    render() {
        return (
            <div>
                <div className="caption"><h2>Booking</h2></div>

                {
                    (this.userLang === "cs" || this.userLang === "sk") ?
                        <div className={"booking-info"}>
                            Rezervace možná pouze e-mailem nebo přes Instagram/Facebook zprávy <br />
                            Pro kontakt použijte jeden z odkazů
                        </div> :
                        <div className={"booking-info"}>
                            Appointment reservation available only via e-mail or Instagram/Facebook messages <br />
                            Click below for contact tattoo artist
                        </div>
                }

                <div className="booking-social">
                    <div onClick={() => this.goTo("mailto:tondriktattoo@gmail.com")}
                         className="booking-info-social-icon studio-info-social-mail bigger-icon" title="E-mail"></div>
                    <div onClick={() => this.goTo("https://www.facebook.com/TondrikTattoo")}
                         className="booking-info-social-icon studio-info-social-fb bigger-icon" title="Facebook"></div>
                    <div onClick={() => this.goTo("https://www.instagram.com/tondrik_tattoo/")}
                         className="booking-info-social-icon studio-info-social-insta bigger-icon" title="Instagram"></div>
                </div>
            </div>
        );
    }
}

export default Booking;
