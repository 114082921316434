import React, { Component } from "react";

class GuestSpots extends Component {


    render() {

        return (
            <div>
                <div className="caption"><h2>Guest Spots</h2></div>

            </div>
        );
    }
}

export default GuestSpots;