import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

import { StyledGallery } from './MyGallery.styled';
import { Gallery, Item } from "react-photoswipe-gallery"
import React, {useEffect, useState} from "react";
import axios from "axios";

const MyGallery = (props) => {
    const [feeds, setFeedsData] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchInstagramPost() {
            const token = process.env.REACT_APP_API_KEY;
            const link = "https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,timestamp&access_token=" + token;

            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE",
                    "Access-Control-Allow-Headers": "X-Requested-With, Access-Control-Allow-Headers, Content-Type, Authorization, Origin, Accept"
                }
            };

            return await axios.get(link, config)
                .catch((e) => { console.log(e) });
        }

        fetchInstagramPost().then((result) => {
            if(result && result.data) setFeedsData(result.data.data);
        }).catch((e) => console.log(e));

        return() => { abortController.abort(); };
    }, []);

    const images =  props.context.map(function(c, idx) {
        return (
            <Item original={c.default} thumbnail={c.default} width="1500" height="2000" key={idx}>
                {({ ref, open }) => (
                    <img ref={ref} onClick={open} src={c.default} className="gallery-item" alt="Gallery" />
                )}
            </Item>
        );
    });

    const instagram = (feeds) && feeds.map((feed) => {

        if(feed.media_type === "VIDEO") return null;
        return (
            <Item original={feed.media_url} thumbnail={feed.media_url} width="1500" height="2000" key={"insta" + feed.id}>
                {({ ref, open }) => {
                    switch (feed.media_type) {
                        case "CAROUSEL_ALBUM":
                            return (<img ref={ref} onClick={open} id={feed.id} src={feed.media_url} alt={feed.caption}
                                         className="gallery-item"/>);
                        default:
                            return (<img ref={ref} onClick={open} id={feed.id} src={feed.media_url} alt={feed.caption}
                                         className="gallery-item"/>)
                    }
                }}
            </Item>
        );

    });

    return (
        <StyledGallery>
            <Gallery>
                { instagram }
                { images }
            </Gallery>
        </StyledGallery>
    );
}

export default MyGallery
