import { Route, BrowserRouter, Switch } from "react-router-dom";

import Start from "./Start";
import Main from "./Main";

function App() {

    return (
        <div>
            <BrowserRouter>
                <Switch>
                    <Route exact={true} path='/' component={Start} />
                    <Route exact={true} path='/main' component={Main} />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;