import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';
import {HashRouter, NavLink} from "react-router-dom";

const Menu = ({ open, ...props }) => {

    const isHidden = !!open;
    const tabIndex = isHidden ? 0 : -1;

    const hideMenu = () => {  props.setOpen(false); };

    return (
        <HashRouter>
            <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
                <ul className="header">
                    <li><NavLink exact to="/" tabIndex={tabIndex} className="navLink" onClick={hideMenu}>Home</NavLink></li>
                    <li><NavLink to="/studio" tabIndex={tabIndex} className="navLink" onClick={hideMenu}>Studio</NavLink></li>
                    {/*<li><NavLink to="/guestSpots" tabIndex={tabIndex} className="navLink" onClick={hideMenu}>Guest Spots</NavLink></li>*/}
                    <li><NavLink to="/gallery" tabIndex={tabIndex} className="navLink" onClick={hideMenu}>Gallery</NavLink></li>
                    <li><NavLink to="/shop" tabIndex={tabIndex} className="navLink" onClick={hideMenu}>Shop</NavLink></li>
                    <li></li>
                    <li><NavLink to="/booking" tabIndex={tabIndex} className="navLink orange" onClick={hideMenu}>Booking</NavLink></li>
                </ul>
            </StyledMenu>
        </HashRouter>
    )
}

Menu.propTypes = { open: bool.isRequired, }

export default Menu;
