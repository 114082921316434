import React, { Component } from "react";
import {Gallery, Item} from "react-photoswipe-gallery";

class Home extends Component {

    userLang = navigator.language || navigator.userLanguage;

    render() {

        switch(this.userLang) {
            case "cs":
            case "sk":

                return (
                    <div>
                        <div className="caption"><h2>Tondrik Tattoo</h2></div>

                        <div className="home-about">
                            <p>Tondrik pochádza z malého mesta z východu Slovenskej republiky, Levoča. Po presťahovaní sa do Prahy začal pôsobiť jako začínajúci tatér. Najprv sa sústredil na tradičný (old school) štýl, no časom ho to prestalo napĺňať, protože sa motívy opakovali a boli „bez nápadu".</p>
                            <p>Postupom času si vytvoril svojský štýl podobný NEOTRADITIONALU, kde kládol dôraz na poctivé a čisté linky, sýtosť farieb a v neposlednom rade „používanie netradičnej škály farieb" ako sú napríklad pastelové a zemité kombinácie.</p>
                            <p>Tondrik sa snaží svoj štýl vždycky tlačiť viac a viac nahor jak kvalitou tak i rozmanitosťou návrhov kreslených podľa prianí klienta, pre ich maximálnu spokojnosť a originalitu návrhu. Poslednou dobou ho zaujala „japonská" (ázijská) tématika návrhov spojená s históriou a symbolikou týchto východných krajín. Dalo by sa nazvať, že si vytvoril svojský NEOASIA štýl, ktorý sa snaží každou prácou stále viac zlepšovať.</p>
                            <p>Okrem pôsobenia v malom rodinnom štúdiu U ČERNÉ KOTVY na pražskom Žižkove, sa snaží cestovať po Európe a jeho tvorby prezentovať na hosťovačkách a konvenčkách. Po skúsenostiach napr. z Talianska, Sicílie, Nemecka, Poľska sa Tondrik „usadil" ako hosťujúci tatér v mestečku ALKMAAR v Holandsku.</p>
                            <p>Poctivá práca, priateľský prístup, okamžitá komunikácia s klientom ( v rámci možností ) sú pre Tondrika najdôležitejím faktorom úspechu a spolupráce.</p>
                        </div>
                        <br />
                        <Gallery>
                            <Item original="./tondrik.jpg" thumbnail="./tondrik.jpg" width="2000" height="1500">
                                {({ ref, open }) => (
                                    <img ref={ref} onClick={open} src="./tondrik.jpg" className="home-tondrik" alt="Tondrik" />
                                )}
                            </Item>
                        </Gallery>
                        <br />
                    </div>
                );

            default:
                return (
                    <div>
                        <h2>Tondrik Tattoo</h2>

                        <div className="home-about">
                            <p>Tondrik comes from Levoča, a little town in the eastern Slovakia. After moving to Prague he started working as a tattoo artist beginner. He based his work on traditional (old school) style at first but it soon stopped filling him for being monotonous.</p>
                            <p>As the time passed, the artist focused his work on a new personal style similar to the NEOTRADITIONAL one, based on pure lines and intense colours but especially on a „non-traditional colour scale“ as pastel colours or earthy colour combinations.</p>
                            <p>Tondrik puts all his efforts in moving his work still forward as for quality, but also offering a large variety of subjects according to client’s expectations, for their complete satisfaction.</p>
                            <p>He recently focused his attention on „japanese“ (asian) subjects in relation to their history and the symbolism of their traditions. It‘ s possible to say that the artist created his own NEOASIA style that he tries to improve with each new work more.</p>
                            <br />
                            <p>Besides his work in a little cosy studio in Žižkov district in Prague, called U ČERNÉ KOTVY, Tondrik travels around Europe to present his work at conventions or be guesting in foreign studios. After some experience in Italy and Sicily, Germany and Poland, Tondrik „got his place“ as a regular guest in the little town of ALKMAAR in Holland.</p>
                            <p>Hard work, honest and friendly approach, immediate communication with the client (if possible) are the most important factors for partnership and success for Tondrik.</p>
                        </div>
                        <br />
                        <Gallery>
                            <Item original="./tondrik.jpg" thumbnail="./tondrik.jpg" width="2000" height="1500">
                                {({ ref, open }) => (
                                    <img ref={ref} onClick={open} src="./tondrik.jpg" className="home-tondrik" alt="Tondrik" />
                                )}
                            </Item>
                        </Gallery>
                        <br />
                    </div>
                );
        }


    }
}

export default Home;