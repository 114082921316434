import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  
  .pswp {
    .pswp img {
        max-width: none;
        object-fit: contain;
    }
  }
  
  *:focus {
    outline: none;
  }
  
  *, *::after, *::before {
    box-sizing: border-box;
  }
  
  ::selection {
        color: ${({ theme }) => theme.primaryLight};
        background: ${({ theme }) => theme.primaryHover};
  }
  
  html, body {
        margin: 0;
        padding: 0;
  }
  
  body {
        background: #000000 url('./mainTheme.png') no-repeat;
        background-size: cover;
        //background-size: 100% 100vh;
        color: ${({ theme }) => theme.primaryLight};
        text-rendering: optimizeLegibility;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        
        @media (max-width: ${({ theme }) => theme.mobile}) {
            background-image: url('./mainThemeMobile.png');
            
        }
  }
  
  h2 {
        font-size: 2rem;
  }
  
  img {
        border-radius: 5px;
        width: 10rem;
        height: auto;
  }
  
  div {
        text-align: center;
  }
  
  a {
    color: ${({ theme }) => theme.primaryHover};
    text-decoration: none;
  }
  
  //SPLASHSCREEN
  
  .startBg {
        position: fixed;
        background: #000000;
        top: 0; left: 0; right: 0; bottom: 0;
  }
  
  .start {
        position: fixed;
        top: 0; left: 0; right: 0; bottom: 0;
        width: 200px;
        height: 600px;
        margin: auto;
  }
  
  .start-logo {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background: url('./tt.png') center no-repeat;
        background-size: 200px;
        width: 200px;
        height: 400px;
  }
  
  .start-enter {
        cursor: pointer;
        position: absolute;
        background: url('./tt_enter.png') center no-repeat;
        background-size: 180px;
        width: 200px;
        height: 200px;
        bottom: 0;
        left: 0;
  }
  
  // MAIN
  
  .content {
        background: rgba(0,0,0,0.8);
        position: absolute;
        width: 80%;
        top: 40%;
        left: 0; right: 0;
        border-radius: 5px;
        min-height: 48%;
        margin: auto;
        padding: 20px;
        
        @media (max-width: ${({ theme }) => theme.mobile}) {
            width: 100%;
            border-radius: 0;
            min-height: 50%;
            height: auto;
            padding: 0;
        }
  }
  
  .content a {
        color: ${({ theme }) => theme.primaryContentHover};
  }
  
  .content a:hover {
        text-decoration: underline;
  }
  
  .caption {
        width: 100%;
        text-align: left;
        margin: 5px 15px;
  }
  
  .home-about {
        margin: 20px;
  }
  
  .home-tondrik {
        cursor: pointer;
        width: 50%;
        border: 1px solid #DDDDDD;
  }
  
  .studio-info {
        position: relative;
        display: inline-flex;
        flex-wrap: wrap;
        margin: 20px auto;
  }
  
  .studio-info-gallery {
        position: relative;
        margin: 10px auto;
        
        @media (max-width: ${({ theme }) => theme.mobile}) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center; 
            align-items: center;
        }
  }
  
  .studio-info-caption {
        font-weight: bold;
        font-size: 20px;
  }
  
  .studio-info-bar {
        position: relative;
        width: 350px; 
        text-align: left;
        margin: 0 10px;
        
        @media (max-width: ${({ theme }) => theme.mobile}) {
            width: 100%;
            text-align: center;
            margin: 10px 0;
        }
  }
  
  iframe {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 160px;
  }
  
  .studio-info-bar-smaller {
        width: 70px;
        
        @media (max-width: ${({ theme }) => theme.mobile}) {
            width: 100%;
            display: flex;
            justify-content: center; 
            align-items: center;
        }
  }
  
  .studio-info-bar li {
        list-style: none;
        margin: 5px 0;
  }
  
  .studio-info-map {
        border: 0;
        border-radius: 5px;
  }
  
  .studio-info-social-icon {
        cursor: pointer;
        width: 54px;
        height: 54px;
  }
  
  .studio-info-social-fb {
        background: url('./fb.png') no-repeat center;
        background-size: 50px;
        
  }
  
  .studio-info-social-insta {
        background: url('./insta.png') no-repeat center;
        background-size: 50px;
  }
  
  .studio-info-social-mail {
        background: url('./mail.png') no-repeat center;
        background-size: 30px;
  }
  
  .studio-info-social-big_cartel {
        background: url('./big_cartel.png') no-repeat center;
        background-size: 30px;
  }
  
  .studio-image {
        cursor: pointer;
        margin: 5px 20px;
        border: 1px solid #DDDDDD;
  }
  
  //===============================
  
  .booking-info {
    font-size: 22px;
    line-height: 50px;
  }
  
  .booking-social {
    display: flex;
    width: 320px;
    margin: 40px auto;
    text-align: center;
    justify-content: center;
  }
  
  .booking-info-social-icon {
        cursor: pointer;
        width: 84px;
        height: 84px;
  }
  
  .bigger-icon {
    background-size: 84px;
    margin: 5px 10px;
  }
  
  //===============================
        
  .shop-list { 
        position: relative;
        display: flex;
        width: 100%;
        flex-flow: wrap;
  }
        
  .shop-item { 
        position: relative;
        cursor: pointer;
        margin: 20px;
        width: 210px;
        font-size: 12px;
  }
  
  .shop-item-header {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
  }
  
  .shop-item-header-name {
    width: 180px;
    font-weight: bold;
    text-align: left;
  }
  
  .shop-item-header-name:hover {
    text-decoration: underline;
  }
  
  .shop-item-header-price {
    color: ${({ theme }) => theme.primaryContentHover};
    font-weight: bold; 
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
  }
  
  .shop-item-image {
    position: relative;
  }
  
  .shop-item-image-product {
    border: 1px solid #DDDDDD;
  }
  
  .shop-item-description {
    font-size: 10px;
  }
  
  .shop-item-soldout {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    height: 45px;
    font-size: 35px;
    text-align: center;
    z-index: 100;
  }
  
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
        
        ul {
            margin: 0;
            padding: 0;
        }
        
  }
  
`
