import styled from 'styled-components';

export const StyledGallery = styled.nav`
    
    .gallery-item {
            cursor: pointer;
            margin: 5px;
    }
    
    .pswp img {
            max-width: none;
            object-fit: contain;
    }
`;